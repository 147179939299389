<template>
    <div id="data-tab" class="tab-pane active">
        <div class="d-flex">
            <!-- <button
                @click="saveDraftData"
                class="btn btn-dark py-2 fw-bold fs-5 text-uppercase rounded me-1"
                type="button"
                :disabled="!enableSubmit"
            >
                <i class="mdi mdi-content-save fs-4 me-1"></i>
                {{ "box.save.draft".trans() }}
            </button> -->
            <button v-if="!isRemoved"
                @click="saveAndPublishConfirm"
                class="btn btn-dark py-2 fw-bold fs-5 text-uppercase rounded me-auto"
                type="button"
            >
                <i class="mdi mdi-pencil-ruler fs-4 me-1"></i>
                {{ "box.save.publish".trans() }}
            </button>

            <button v-else
                data-bs-toggle="modal"
                data-bs-target="#saveAndDeleteModal"
                class="btn btn-dark py-2 fw-bold fs-5 text-uppercase rounded me-auto"
                type="button"
            >
                <i class="mdi mdi-pencil-ruler fs-4 me-1"></i>
                {{ "box.save.delete".trans() }}
            </button>

            <div class="position-relative h-100">
                <button
                    class="btn btn-outline box-drawer-tooltip text-dark p-0 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-ellipsis-h me-1"></i>
                </button>

                <div class="dropdown-menu dropdown-menu-card p-3">
                    <span
                        :class="[
                            'cursor-pointer d-flex align-items-center text-decoration-underline mb-1',
                            props.currentData.Type == 'loyalty'
                                ? 'option-disabled'
                                : '',
                        ]"
                        data-bs-toggle="modal"
                        data-bs-target="#mergeCardModal"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.9998 20.41L18.4098 19L14.9998 15.59L13.5898 17M7.49984 8H10.9998V13.59L5.58984 19L6.99984 20.41L12.9998 14.41V8H16.4998L11.9998 3.5"
                                fill="#323a46"
                            />
                        </svg>
                        <p class="mb-0 ms-1 text-dark">
                            {{ "box.options.merge_box".trans() }}
                        </p>
                    </span>
                    <span
                        :class="[
                            'cursor-pointer d-flex align-items-center text-decoration-underline mb-1',
                            (props.currentData.katalogProducts &&
                            props.currentData.katalogProducts.length < 1) ||
                            props.currentData.Type == 'loyalty'
                                ? 'option-disabled'
                                : '',
                        ]"
                        data-bs-toggle="modal"
                        data-bs-target="#splitCardModal"
                    >
                        <svg
                            class="mr-1"
                            width="20"
                            height="20"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14 4L16.29 6.29L13.41 9.17L14.83 10.59L17.71 7.71L20 10V4M10 4H4V10L6.29 7.71L11 12.41V20H13V11.59L7.71 6.29"
                                fill="#323a46"
                            />
                        </svg>
                        <p class="mb-0 ms-1 text-dark">
                            {{ "box.options.split_box".trans() }}
                        </p>
                    </span>
                    <span
                        :class="[
                            'cursor-pointer d-flex align-items-center text-decoration-underline mb-1',
                            props.currentData.virtualCard
                                ? 'option-disabled'
                                : '',
                        ]"
                        data-bs-toggle="modal"
                        data-bs-target="#createVirtualModal"
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style="width: 20px; height: 20px"
                        >
                            <path
                                d="M19 21H8V7H19M19 5H8C7.46957 5 6.96086 5.21071 6.58579 5.58579C6.21071 5.96086 6 6.46957 6 7V21C6 21.5304 6.21071 22.0391 6.58579 22.4142C6.96086 22.7893 7.46957 23 8 23H19C19.5304 23 20.0391 22.7893 20.4142 22.4142C20.7893 22.0391 21 21.5304 21 21V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5ZM16 1H4C3.46957 1 2.96086 1.21071 2.58579 1.58579C2.21071 1.96086 2 2.46957 2 3V17H4V3H16V1Z"
                                fill="#323a46"
                            />
                        </svg>
                        <p class="mb-0 ms-1 text-dark">
                            {{ "box.options.create_virtual".trans() }}
                        </p>
                    </span>
                </div>
            </div>
        </div>

        <div class="alert alert-secondary mt-3" v-if="newSplitCard">
            <i class="mdi mdi-alert-outline me-2"></i>
            {{ "box.alert.card_split_success".trans() }}
            <span
                @click="showNewSplitBox"
                v-if="newSplitCard.isNewCard"
                class="text-decoration-underline cursor-pointer"
            >
                {{ "box.alert.open_new_card".trans() }}
            </span>
            <span
                @click="showNewSplitBox"
                v-if="!newSplitCard.isNewCard"
                class="text-decoration-underline cursor-pointer"
            >
                {{ "box.alert.open_destination_card".trans() }}
            </span>
        </div>

        <BoxChangesCard
            v-if="showChangesContainer"
            :addedProducts="addedProductIds.length"
            :removedProducts="removedProductIds.length"
            :changedProducts="changedProductIds.length"
        />

        <div v-if="showErrorMessage" class="mt-3">
            <p class="mb-0 text-danger fw-bold">
                {{ "box.submit_data.invalid".trans() }}
            </p>
        </div>

        <div v-if="showSuccessMessage" class="mt-3">
            <p class="mb-0 text-dark fw-bold">
                {{ "box.submit.success".trans() }}
            </p>
        </div>

        <LoyaltyFlag v-if="draftData.Type == 'loyalty'" class="mt-3" :maskType="draftData.masterMask.maskType" :LoyaltyMinItemCount="draftData.LoyaltyMinItemCount" :LoyaltyItemCount="draftData.LoyaltyItemCount" :LoyaltyGratisItemCount="draftData.LoyaltyGratisItemCount" />

        <UneditableGeneralBoxData :data="currentData" :draftData="draftData" />

        <div class="text-data-container mt-3">
            <div class="mb-2">
                <label
                    for="box-brand"
                    class="form-label text-dark fw-normal fs-5"
                >
                    {{ "box.text_data.brand".trans() }}
                </label>
                <select ref="brandSelect" class="brand-select">
                    <option value="">{{ 'box.text_data.brand.placeholder.'.trans() }}</option>
                    <option v-for="option in options" :key="option.id">
                        {{ option.label }}
                    </option>
                </select>
            </div>

            <div class="mb-2">
                <label
                    for="box-name"
                    class="form-label text-dark fw-normal fs-5"
                >
                    {{ "box.text_data.name".trans() }}
                </label>
                <input
                    v-model="cardName"
                    id="box-name"
                    class="form-control text-dark"
                    :class="{ 'border-danger': cardNameIsEmpty }"
                    type="text"
                    @keyup="cardNameKeyUp"
                />
            </div>

            <div class="mb-2">
                <label
                    for="box-description"
                    class="form-label text-dark fw-normal fs-5"
                >
                    {{ "box.text_data.description".trans() }}
                </label>
                <textarea
                    v-model="cardDescription"
                    id="box-description"
                    class="form-control text-dark"
                    :class="{ 'border-danger': cardDescriptionIsEmpty }"
                    rows="5"
                    @keyup="cardDescriptionKeyUp"
                ></textarea>
            </div>

            <div class="mb-2">
                <label
                    for="box-note"
                    class="form-label text-dark fw-normal fs-5"
                >
                    {{ "box.text_data.note".trans() }}
                </label>
                <textarea
                    v-model="cardNote"
                    id="box-note"
                    class="form-control text-dark"
                    :class="{ 'border-danger': cardNoteIsEmpty }"
                    rows="5"
                    @keyup="cardNoteKeyUp"
                ></textarea>
            </div>
        </div>

        <!--
            - `marketingPackageLabel` - is taken from trueData which means if card is "saved as draft", than draftData is true data, otherwise currentData
            - `packageGroups` - always use draftData because it should render how packages will look after chages are accepted
        -->
        <WeightAndUnits
            v-if="draftData && currentData"
            :marketingPackageLabel="cardSingleStore.getTrueData().displayMeasureValue"
            :packageGroups="draftData.MeasureUnitGroup"
            :isEmpty="weightAndMeasureTotalIsEmpty"
            @valueUpdate="updateWeightAndUnitsTotal"
        />

        <p v-if="draftData && draftData.Type == 'loyalty'"
            class="text-dark fw-bold mt-3 mb-1"
        >
            {{ 'Loyalty:'.trans() }}
        </p>

        <WeightAndUnits
            v-if="draftData && currentData && draftData.Type == 'loyalty'"
            :marketingPackageLabel="cardSingleStore.getTrueData().LoyaltyMeasureValue"
            :packageGroups="draftData.LoyaltyMeasureUnitGroup"
            :isEmpty="weightAndMeasureLoyaltyTotalIsEmpty"
            @valueUpdate="updateWeightAndUnitsLoyaltyTotal"
        />

        <div
            class="box-prices-container d-flex bg-soft-secondary rounded-2 p-3 mt-3" :class="{ 'd-none': shouldHide }"
        >
            <div :class="[!priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-50']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                    for="box-total-data"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.price_from".trans() }}
                </label>
                <div class="position-relative">
                    <select ref="select" class="price-from-select">
                        <option value="">
                            {{ "box.price_data.price_from".trans() }}
                        </option>
                    </select>
                    <p
                        v-if="priceFromValueIsEmpty"
                        class="mb-0 text-danger fw-bold"
                    >
                        {{ "box.price_data.please_select_price".trans() }}
                    </p>
                    <i class="mdi mdi-triangle fs-7 text-dark position-absolute pointer-events-none"></i>
                </div>
            </div>
            <div :class="[priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                    for="box-total-data"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.discount".trans() }}
                </label>
                <input
                    v-model="discountPrice"
                    id="box-total-data"
                    class="form-control text-dark pointer-events-none"
                    type="text"
                    @blur="discountPriceBlur"
                />
            </div>
            <div :class="[priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                    for="box-total-data"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.regular_price".trans() }}
                </label>
                <input
                    v-model="price"
                    id="box-total-data"
                    class="form-control text-dark pointer-events-none"
                    type="text"
                    @blur="priceBlur"
                />
            </div>
            <div :class="[priceFromSwitchSelected ? 'w-50' : 'w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                    for="box-total-data"
                >
                    <br />
                    {{ "box.price_data.percentage".trans() }}
                </label>
                <input
                    v-model="discountPercentage"
                    id="box-total-data"
                    class="form-control text-dark"
                    type="text"
                    placeholder="%"
                    @blur="discountPercentageBlur"
                    :disabled="priceFromSwitchSelected || allProductIds.length <= 1"
                />
            </div>
        </div>
        <div
            class="box-switches-container d-flex align-items-center mt-3 text-dark fs-5"
        >
            <div class="switch-container d-flex justify-content-center w-30" :class="{ 'd-none': shouldHide }">
                <span class="me-1 nowrap">{{
                    "box.switch_data.price".trans()
                }}</span>
                <label class="switch" :class="{'pointer-events-none': allProductIds.length <= 1}">
                    <input type="checkbox" v-model="priceFromSwitchSelected" @change="priceFromSwitchChange" :disabled="allProductIds.length <= 1"/>
                    <span class="slider round"></span>
                </label>
            </div>
            <span class="vr mx-2 text-light" :class="{ 'd-none': shouldHide }"></span>
            <div class="switch-container d-flex justify-content-center" :class="{ 'w-30': !shouldHide }">
                <span class="me-1 nowrap">{{
                    "box.switch_data.web_only".trans()
                }}</span>
                <label class="switch">
                    <input type="checkbox" v-model="isWebOnly" @change="isWebOnlyChange"/>
                    <span class="slider round"></span>
                </label>
            </div>
            <span class="vr mx-2 text-light" :class="{ 'd-none': shouldHide }"></span>
            <div class="switch-container d-flex justify-content-center align-items-center w-30" :class="{ 'd-none': shouldHide }">
                <span class="me-1 nowrap">{{
                    "box.switch_data.installments".trans()
                }}</span>
                <label class="switch">
                    <input type="checkbox" v-model="installment"
                        @change="installmentChange"
                        :disabled="!installmentAmount || installmentAmount <= 0 || installment == null"
                    />
                    <span class="slider round"></span>
                </label>
                <template v-if="installment">
                    <span class="ms-2 nowrap">{{
                        "box.switch_data.installments.amount".trans()
                    }}</span>
                    <input
                        v-model="installmentAmount"
                        class="width-75 form-control text-dark ms-1"
                        type="text"
                        disabled
                    />
                    <span class="price-unit ms-1" style="margin-top: 3px;">€</span>
                </template>
            </div>
            <span class="vr mx-2 text-light" :class="{ 'd-none': shouldHide }"></span>
            <div
                class="switch-container justify-content-center w-50 d-flex align-items-center" :class="{ 'd-none': shouldHide }"
            >
                <span class="me-1 nowrap">{{
                    "box.switch_data.discount".trans()
                }}</span>
                <label class="switch">
                    <input
                        type="checkbox"
                        v-model="hasMarketingDiscount"
                        @change="emptyMarketingPercentage"
                    />
                    <span class="slider round"></span>
                </label>
                <input
                    v-model="marketingDiscount"
                    class="form-control text-dark ms-1 w-auto"
                    :class="{ 'border-danger': marketingDiscountIsEmpty, 'width-75': installment }"
                    type="text"
                    placeholder="%"
                    :disabled="!hasMarketingDiscount"
                    @blur="marketingDiscountBlur"
                />
            </div>
        </div>

        <template v-if="draftData && draftData.Type == 'loyalty'">
            <p class="text-dark fw-bold mt-3 mb-0">
                {{ 'Loyalty:'.trans() }}
            </p>
            <div class="box-prices-container d-flex bg-soft-secondary rounded-2 p-3 mt-1">
                <div class="me-1 w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                        for="box-total-data"
                    >
                        {{ "box.price_data.currency".trans() }} <br />
                        {{ "loyalty.price".trans() }}
                    </label>
                    <input
                        v-model="loyaltyPrice"
                        id="box-total-data"
                        class="form-control text-dark pointer-events-none"
                        type="text"
                    />
                </div>
                <div class="me-1 w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                        for="box-total-data"
                    >
                        {{ "box.price_data.currency".trans() }} <br />
                        {{ "box.price_data.regular_price".trans() }}
                    </label>
                    <input
                        v-model="loyaltyPriceRegular"
                        id="box-total-data"
                        class="form-control text-dark pointer-events-none"
                        type="text"
                    />
                </div>
                <div class="w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                        for="box-total-data"
                    >
                        <br />
                        {{ "loyalty.percentage".trans() }}
                    </label>
                    <input
                        v-model="loyaltyPercentage"
                        id="box-total-data"
                        class="form-control text-dark"
                        type="text"
                        placeholder="%"
                        @blur="loyaltyPercentageBlur"
                        :disabled="allProductIds.length <= 1"
                    />
                </div>
            </div>
            <div class="box-switches-container d-flex align-items-center mt-3 text-dark fs-5">
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container justify-content-center w-50 d-flex align-items-center">
                    <span class="me-1 nowrap">{{ "loyalty.switch_data.discount".trans() }}</span>
                    <label class="switch">
                        <input
                            type="checkbox"
                            v-model="hasLoyaltyMarketingDiscount"
                            @change="emptyLoyaltyMarketingPercentage"
                        />
                        <span class="slider round"></span>
                    </label>
                    <input
                        v-model="loyaltyMarketingDiscount"
                        class="form-control text-dark ms-1 w-auto"
                        :class="{ 'border-danger': loyaltyMarketingDiscountIsEmpty }"
                        type="text"
                        placeholder="%"
                        :disabled="!hasLoyaltyMarketingDiscount"
                        @blur="loyaltyMarketingDiscountBlur"
                    />
                </div>
            </div>
        </template>

        <div class="d-flex align-items-center mt-4 text-dark fs-5">
            <p class="m-0">
                {{ selectedProductsIds.length }}
                {{ "box.products.from".trans() }}
                {{ allProductIds.length }}
                {{ "box.products.selected".trans() }}
            </p>
            <button
                v-if="selectedProductsIds.length < allProductIds.length"
                class="btn btn-outline ms-2 p-0 text-decoration-underline text-dark fs-5"
                @click="selectAllProducts"
            >
                {{ "box.products.select_all".trans() }}
            </button>
            <button
                v-else
                class="btn btn-outline ms-2 p-0 text-decoration-underline text-dark fs-5"
                @click="unselectAllProducts"
            >
                {{ "box.products.unselect_all".trans() }}
            </button>
        </div>
        <div class="products-container mt-4 ps-2">
            <BoxProduct
                v-for="(productId, index) in allProductIds"
                :key="productId"
                :cardStatus="cardSingleStore.getDraftStatus()"
                :currentData="cardSingleStore.getCurrentProduct(productId)"
                :draftData="cardSingleStore.getDraftProduct(productId) || cardSingleStore.getCurrentProduct(productId)"
                :originalData="cardSingleStore.getOriginalProduct(productId)"
                :cardLevelSelectedImages="cardLevelSelectedImages"
                :index="index + 1"
                :isAdded="addedProductIds.indexOf(productId) >= 0"
                :isRemoved="removedProductIds.indexOf(productId) >= 0"
                :isChanged="changedProductIds.indexOf(productId) >= 0"
                :showFlags="showProductFlags"
                @productImagesUpdated="updateSelectedImages"
                @productDescriptionUpdated="updateProductDescription"
                @productSelectedUpdated="updateSelectedProducts"
                @productImagesReverted="revertSelectedImages"
            />
        </div>
    </div>
</template>

<script setup>
    import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
    import { useCardSingleStore } from '../../../js/frontend/stores/cardSingle'
    import { saveAndPublish, saveDraft, unlock } from '../../../js/frontend/api/card'
    import BoxChangesCard from './BoxChangesCard'
    import UneditableGeneralBoxData from './UneditableGeneralBoxData'
    import WeightAndUnits from './WeightAndUnits'
    import BoxProduct from './BoxProduct'
    import LoyaltyFlag from '../Flags/LoyaltyFlag'

    const props = defineProps({
        draftData: {
            type: Object,
            required: true,
        },
        currentData: {
            type: Object,
            required: true,
        },
        originalCardData: {
            type: Object,
            required: true
        },
        pricesForDropdown: {
            type: Array,
            required: true,
        }
    })

    const emit = defineEmits(['reloadBacklogAndPages', 'saveAndPublish'])

    const cardSingleStore = useCardSingleStore()

    const newSplitCard = ref(cardSingleStore.getNewSplitCard);

    const cardBrand = ref(props.draftData.Brand)
    const cardName = ref(props.draftData.Label)
    const cardDescription = ref(props.draftData.Description)
    const cardNote = ref(props.draftData.DesignerNote)

    const isRemoved = ref(props.draftData.Removed)

    const weightAndMeasureTotal = ref(props.draftData.displayMeasureValue)
    const weightAndMeasureLoyaltyTotal = ref(props.draftData.LoyaltyMeasureValue)

    const discountPrice = ref(props.draftData.LowestPrice)
    const price = ref(props.draftData.Price)
    const discountPercentage = ref(props.draftData.Percentage + ' %')

    const loyaltyPrice = ref(props.draftData.LoyaltyPrice)
    const loyaltyPercentage = ref(props.draftData.LoyaltyPercentage ? props.draftData.LoyaltyPercentage + ' %' : '0 %')
    const loyaltyPriceRegular = ref(props.draftData.LoyaltyPriceRegular)

    const priceFrom = ref(props.draftData.PriceFrom)
    const isWebOnly = ref(props.draftData.isWebOnly)
    const installment = ref(props.draftData.Installment)
    const installmentAmount = ref(props.draftData.installmentRate)
    const marketingDiscount = ref((props.draftData.MarketingDiscount ? props.draftData.MarketingDiscount : '0') + ' %')
    const hasMarketingDiscount = ref(props.draftData.MarketingDiscount ? true : false)
    const priceFromSwitchSelected = ref((!priceFrom.value || priceFrom.value == '0') ? false : true)
    let priceFromValue = null;

    const isLoyalty = props.draftData.Type == 'loyalty'
    const loyaltyMarketingDiscount = ref(props.draftData.LoyaltyMarketingDiscount ? props.draftData.LoyaltyMarketingDiscount : '0')
    const hasLoyaltyMarketingDiscount = ref(props.draftData.LoyaltyMarketingDiscount ? true : false)

    const allProductIds = ref(cardSingleStore.getAllProductIds)
    const selectedProductsIds = ref(cardSingleStore.getSelectedProducts)

    const addedProductIds = ref(cardSingleStore.getAddedProducts)
    const removedProductIds = ref(cardSingleStore.getRemovedProducts)
    const changedProductIds = ref(cardSingleStore.getChangedProducts)
    const brands = ref(cardSingleStore.getBrands)
    const cardLevelSelectedImages = ref(cardSingleStore.getCardLevelSelectedImages)

    const cardBrandIsEmpty = ref(false)
    const cardNameIsEmpty = ref(false)
    const cardDescriptionIsEmpty = ref(false)
    const cardNoteIsEmpty = ref(false)
    const weightAndMeasureTotalIsEmpty = ref(false)
    const weightAndMeasureLoyaltyTotalIsEmpty = ref(false)
    const priceFromValueIsEmpty = ref(false);
    const marketingDiscountIsEmpty = ref(false)
    const loyaltyMarketingDiscountIsEmpty = ref(false)

    const productsUserChanges = ref({})

    const showErrorMessage = ref(false)
    const showSuccessMessage = ref(false)

    const showChangesContainer = computed(() => addedProductIds.value.length + removedProductIds.value.length + changedProductIds.value.length > 0)
    const showProductFlags = ref(true)

    const brandSelect = ref(null)
    const select = ref(null);

    const isDirty = ref({
        'brand': false,
        'name': false,
        'description': false,
        'note': false,
        'weightAndMeasureTotal': false,
        'weightAndMeasureLoyaltyTotal': false,
        'priceFrom': false,
        'discountPrice': false,
        'price': false,
        'percentage': false,
        'loyaltyPercentage': false,
        'isWebOnly': false,
        'installment': false,
        'marketingDiscount': false,
        'loyaltyMarketingDiscount': false
    })

    const productsAreDirty = ref(false)
    const draftExists = ref(false)

    const enableSubmit = computed(() =>
        isDirty.value.brand ||
        isDirty.value.name ||
        isDirty.value.description ||
        isDirty.value.note ||
        isDirty.value.weightAndMeasureTotal ||
        isDirty.value.weightAndMeasureLoyaltyTotal ||
        isDirty.value.priceFrom ||
        isDirty.value.discountPrice ||
        isDirty.value.price ||
        isDirty.value.percentage ||
        isDirty.value.loyaltyPercentage ||
        isDirty.value.isWebOnly ||
        isDirty.value.installment ||
        isDirty.value.marketingDiscount ||
        isDirty.value.loyaltyMarketingDiscount ||
        productsAreDirty.value
    )

    // if the box type is loyalty and Price, LowestPrice and Percentage are equal to "0.00" or null, parts of the template have to be hidden
    const shouldHide = computed(() => {
      return (
        props.draftData &&
        props.draftData.Type === 'loyalty' &&
        (props.draftData.Price === '0.00' || props.draftData.Price === null) &&
        (props.draftData.LowestPrice === '0.00' || props.draftData.LowestPrice === null) &&
        (props.draftData.Percentage === '0.00' || props.draftData.Percentage === null)
      );
    });

    onMounted(() => {
        $(brandSelect.value).selectize({
            maxItems: 1,
            valueField: 'id',
            labelField: 'text',
            searchField: ['text'],
            diacritics: true,
            preload: 'focus',
            load: async function(query, callback) {
                await cardSingleStore.fetchCardBrands(query)
                return callback(brands.value)
            },
            onInitialize: function() {
                const selectizeInstance = $(brandSelect.value)[0].selectize

                if (cardBrand.value) {
                    selectizeInstance.addOption({ 'id': cardBrand.value.value, 'text': cardBrand.value.label })
                    selectizeInstance.setValue(cardBrand.value.value)
                }
            },
            onChange: function(val) {
                cardBrand.value = val

                if ($(brandSelect.value)[0].selectize.order == 1) return

                // if (props.originalCardData.Brand) {
                //     if (cardBrand.value != props.originalCardData.Brand.value) isDirty.value.brand = true
                //     else isDirty.value.brand = false

                // } else {
                //     if (cardBrand.value) isDirty.value.brand = true
                //     else isDirty.value.brand = false
                // }
                isDirty.value.brand = true
            }
        })

        $(select.value).selectize({
            valueField: "value",
            labelField: "text",
            searchField: ["text"],
            diacritics: true,
            options: props.pricesForDropdown,
            onChange: function (selectedValue) {
                priceFromValue = selectedValue;
                if (selectedValue.length > 0) {
                    priceFromValueIsEmpty.value = false;
                }

                // if (priceFromValue != props.originalCardData.PriceFrom) isDirty.value.priceFrom = true
                // else isDirty.value.priceFrom = false
                isDirty.value.priceFrom = true
            },
        });

        $(select.value)[0].selectize.setValue(priceFrom.value)
    })

    onBeforeUnmount(() => {
        if ($(select.value)[0].selectize) {
            $(select.value)[0].selectize.destroy();
        }
    })

    function saveAndPublishConfirm() {
        emit('saveAndPublish')
    }

    function formatValue(val, name) {
        if (!val || !name) return;

        const numberVal = parseFloat(val.replace(",", "."));
        let formattedVal = null;

        if (numberVal) {
            formattedVal = numberVal;

            formattedVal = numberVal;
            if (numberVal < 0 && name != 'marketingDiscount' && name != 'loyaltyMarketingDiscount') {
                formattedVal = 0;
            }
        } else {
            formattedVal = 0;
        }

        formattedVal = formattedVal.toString().replace(".", ",");

        if (name == "discountPercentage") {
            discountPercentage.value = formattedVal + " %";
        }

        if (name == "loyaltyPercentage") {
            loyaltyPercentage.value = formattedVal + " %";
        }

        if (name == "marketingDiscount") {
            marketingDiscount.value = formattedVal + " %";
        }

        if (name == "loyaltyMarketingDiscount") {
            loyaltyMarketingDiscount.value = formattedVal + " %";
        }

        if (name == "price") {
            price.value = formattedVal;
        }

        if (name == "discountPrice") {
            discountPrice.value = formattedVal;
        }
    }

    function updateWeightAndUnitsTotal(emitVal) {
        weightAndMeasureTotal.value = emitVal;
        weightAndMeasureTotalIsEmpty.value = false;

        showErrorMessage.value = false

        // if (weightAndMeasureTotal.value != props.originalCardData.displayMeasureValue) isDirty.value.weightAndMeasureTotal = true
        // else isDirty.value.weightAndMeasureTotal = false
        isDirty.value.weightAndMeasureTotal = true
    }

    function updateWeightAndUnitsLoyaltyTotal(emitVal) {
        weightAndMeasureLoyaltyTotal.value = emitVal; //
        weightAndMeasureLoyaltyTotalIsEmpty.value = false;

        showErrorMessage.value = false
        isDirty.value.weightAndMeasureLoyaltyTotal = true
    }

    function emptyMarketingPercentage(e) {
        if (!e.target.checked) {
            marketingDiscount.value = "0 %";
        }

        // if (props.originalCardData.MarketingDiscount && !hasMarketingDiscount.value) isDirty.value.marketingDiscount = true
        // else if (marketingDiscount.value != props.originalCardData.MarketingDiscount) isDirty.value.marketingDiscount = true
        // else isDirty.value.marketingDiscount = false
        isDirty.value.marketingDiscount = true

        marketingDiscountIsEmpty.value = false
    }

    function emptyLoyaltyMarketingPercentage(e) {
        if (!e.target.checked) {
            loyaltyMarketingDiscount.value = "0 %";
        }

        isDirty.value.loyaltyMarketingDiscount = true
        loyaltyMarketingDiscountIsEmpty.value = false
    }

    async function saveDraftData() {
        const isPayloadValid = validateFields();

        if (!isPayloadValid) return;

        let payload = collectPayload(true);

        try {
            await saveDraft(payload);

            emit('reloadBacklogAndPages')

            showErrorMessage.value = false;
            showSuccessMessage.value = true;
            showProductFlags.value = false;
            draftExists.value = true;

            productsAreDirty.value = false;
            isDirty.value = {
                'brand': false,
                'name': false,
                'description': false,
                'note': false,
                'weightAndMeasureTotal': false,
                'weightAndMeasureLoyaltyTotal': false,
                'priceFrom': false,
                'discountPrice': false,
                'price': false,
                'percentage': false,
                'loyaltyPercentage': false,
                'isWebOnly': false,
                'installment': false,
                'marketingDiscount': false,
                'loyaltyMarketingDiscount': false
            };
        } catch (error) {
            throw error;
        }
    }

    async function saveAndPublishData(isDeleteOnly = false) {
        const isPayloadValid = validateFields();

        if (!isPayloadValid && !isDeleteOnly) return;

        const payload = collectPayload();

        try {
            await saveAndPublish(payload);

            emit('reloadBacklogAndPages')

            showErrorMessage.value = false;
            showSuccessMessage.value = true;
            showProductFlags.value = false;

            bootstrap.Offcanvas.getInstance($('#drawerBoxEdit')[0]).hide()

        } catch (error) {
            throw error;
        }
    }

    function showNewSplitBox() {
        unlock(props.currentData.id)
        cardSingleStore.fetchData(newSplitCard.value.id);
        cardSingleStore.setNewSplitCard(null);
    }

    function validateFields() {
        let isPayloadValid = true;

        if ((!priceFromValue || priceFromValue.length < 0) && priceFromSwitchSelected.value) {
            priceFromValueIsEmpty.value = true;
            isPayloadValid = false;
        }

        if ((!marketingDiscount.value.split(' ')[0]) && hasMarketingDiscount.value) {
            marketingDiscountIsEmpty.value = true;
            isPayloadValid = false;
        }

        if ((!loyaltyMarketingDiscount.value.split(' ')[0]) && hasLoyaltyMarketingDiscount.value) {
            loyaltyMarketingDiscountIsEmpty.value = true;
            isPayloadValid = false;
        }

        if (!isPayloadValid) showErrorMessage.value = true;

        return isPayloadValid;
    }

    function collectPayload(isDraft = false) {
        let payload = {};

        payload["id"] = props.currentData.id;
        payload["brand"] = cardBrand.value;
        payload["label"] = cardName.value;
        payload["description"] = cardDescription.value;
        payload["designerNote"] = cardNote.value;
        payload["displayMeasureValue"] = weightAndMeasureTotal.value || "";
        payload["loyaltyMeasureValue"] = weightAndMeasureLoyaltyTotal.value || "";
        payload["lowestPrice"] = priceFromSwitchSelected.value ? "" : discountPrice.value;
        payload["price"] = priceFromSwitchSelected.value ? "" : price.value;
        payload["percentage"] = discountPercentage.value.split(" ")[0];
        payload["loyaltyPercentage"] = loyaltyPercentage.value.split(" ")[0];

        if (priceFromSwitchSelected.value) {
            payload["priceFrom"] =
                priceFromValue != null && priceFromValue.length > 0
                    ? priceFromValue
                    : "";
        } else {
            payload["priceFrom"] = ""
        }

        payload["isWebOnly"] = isWebOnly.value ? true : false;
        payload["installment"] = installment.value;
        payload["marketingDiscount"] = hasMarketingDiscount.value
            ? marketingDiscount.value
            : false;
        payload["loyaltyMarketingDiscount"] = hasLoyaltyMarketingDiscount.value
            ? loyaltyMarketingDiscount.value
            : false;

        payload = appendProductsPayload(payload);

        payload['departmentId'] = props.draftData.departmentMask.value;
        payload['topic'] = props.draftData.Topic;
        payload['departmentMaskId'] = props.draftData.departmentMaskId;

        if (isDraft) payload["draft"] = true;

        return payload;
    }

    function selectAllProducts() {
        cardSingleStore.selectAllProducts();
        allProductIds.value.map((id) =>
            updateSelectedProducts({ productId: id, isSelected: true })
        );
    }

    function unselectAllProducts() {
        cardSingleStore.unselectAllProducts();
        allProductIds.value.map((id) =>
            updateSelectedProducts({ productId: id, isSelected: false })
        );
    }

    function updateSelectedProducts(emitData) {
        const { productId, isSelected } = emitData;

        if (!productsUserChanges.value[productId]) {
            createEmptyKey(productId);
        }

        productsUserChanges.value[productId]["selected"] = isSelected;
    }

    function updateSelectedImages(emitData) {
        const { productId, images } = emitData;

        if (!productsUserChanges.value[productId]) {
            createEmptyKey(productId);
        }

        productsUserChanges.value[productId]["images"] = images;
    }

    // function revertSelectedImages(productId) {
    //     if (
    //         productsUserChanges.value[productId] &&
    //         productsUserChanges.value[productId]["images"]
    //     ) {
    //         delete productsUserChanges.value[productId]["images"];

    //         if (Object.keys(productsUserChanges.value[productId]).length == 0)
    //             delete productsUserChanges.value[productId];
    //     }
    // }

    function updateProductDescription(emitData) {
        const { productId, description } = emitData;
        if (!productsUserChanges.value[productId]) {
            createEmptyKey(productId);
        }

        productsUserChanges.value[productId]["description"] = description;
    }

    function isProductFiledDirty (productId, filedName) {
        if (
            productsUserChanges.value[productId] &&
            typeof productsUserChanges.value[productId][filedName] !==
                "undefined"
        ) {
            return true
        } else {
            return false
        }
    }

    function getProductFiledDirtyValue (productId, filedName) {

        if (isProductFiledDirty(productId, filedName)) {
            return productsUserChanges.value[productId][filedName]
        } else {
            return null
        }
    }

    function getProductFiledValue (productId, filedName) {
        const productDraftData = cardSingleStore.getDraftProduct(productId);
        const productCurrentData = cardSingleStore.getCurrentProduct(productId);

        return cardSingleStore.isSavedAsDraft() ? (productDraftData || {})[filedName] : (productCurrentData || {})[filedName];

    }

    function appendProductsPayload(payload) {
        let productsPayload = allProductIds.value.map((productId) => {
            // @TODO: after we solve image value retriving we will be able cleanup this code
            // Purpos of productOriginalData is not clear so i would not change it
            const productDraftData = cardSingleStore.getDraftProduct(productId);
            const productCurrentData = cardSingleStore.getCurrentProduct(productId);
            const productOriginalData = cardSingleStore.getOriginalProduct(productId);

            let description = "";
            let selected = false;
            let assets = [];

            if (isProductFiledDirty(productId, "description")) {
                description = getProductFiledDirtyValue(productId, "description");
            } else {
                description = getProductFiledValue(productId, "katalogProductName");
            }

            if (isProductFiledDirty(productId, "selected")) {
                selected = getProductFiledDirtyValue(productId, "selected");
            } else {
                selected = getProductFiledValue(productId, "selected");
            }

            if (isProductFiledDirty(productId, "images")) {
                assets = getProductFiledDirtyValue(productId, "images");
            } else if (productDraftData) {
                // Not sure what happening here so i will leave it for now
                if (selected) {
                        assets = productDraftData.katalogProductImages

                } else {
                    assets = imagesIntersection(productDraftData.Product.Gallery, [
                        ...props.draftData.katalogProductImages,
                        ...productDraftData.katalogProductImages
                    ])
                }
            }

            assets = JSON.parse(JSON.stringify(assets));

            return {
                id: props.currentData.id,
                product: productId,
                catalogProductName: description,
                selected: selected,
                assets: assets,
            };
        });

        payload['products'] = productsPayload

        return payload;
    }

    function createEmptyKey(id) {
        productsUserChanges.value[id] = { }
    }

    function imagesIntersection(gallery, cardImages) {
        let result = []
        const galleryIds = gallery.map(image => image.id)

        for (let elem of galleryIds){
            if (cardImages.includes(elem)){
                result.push(elem)
            }
        }

        return result
    }

    function cardNameKeyUp() {
        cardNameIsEmpty.value = false
        showErrorMessage.value = false

        // if (cardName.value != props.originalCardData.Label) isDirty.value.name = true
        // else isDirty.value.name = false
        isDirty.value.name = true
    }

    function cardDescriptionKeyUp() {
        cardDescriptionIsEmpty.value = false
        showErrorMessage.value = false

        // if (cardDescription.value != props.originalCardData.Description) isDirty.value.description = true
        // else isDirty.value.description = false
        isDirty.value.description = true
    }

    function cardNoteKeyUp() {
        cardNoteIsEmpty.value = false
        showErrorMessage.value = false

        // if (cardNote.value != props.originalCardData.DesignerNote) isDirty.value.note = true
        // else isDirty.value.note = false
        isDirty.value.note = true
    }

    function priceFromSwitchChange() {
        // if (priceFrom.value && !priceFromSwitchSelected.value) isDirty.value.priceFrom = true
        // else if (priceFromValue != props.originalCardData.PriceFrom) isDirty.value.priceFrom = true
        // else isDirty.value.priceFrom = false
        isDirty.value.priceFrom = true
    }

    function discountPriceBlur() {
        formatValue(discountPrice.value, 'discountPrice')

        // if (discountPrice.value != props.originalCardData.LowestPrice) isDirty.value.discountPrice = true
        // else isDirty.value.discountPrice = false
        isDirty.value.discountPrice = true
    }

    function priceBlur() {
        formatValue(price.value, 'price')

        // if (price.value != props.originalCardData.Price) isDirty.value.price = true
        // else isDirty.value.price = false
        isDirty.value.price = true
    }

    function discountPercentageBlur() {
        formatValue(discountPercentage.value, 'discountPercentage')

        // if (discountPercentage.value != props.originalCardData.Percentage) isDirty.value.percentage = true
        // else isDirty.value.percentage = false
        isDirty.value.percentage = true
    }

    function loyaltyPercentageBlur() {
        formatValue(loyaltyPercentage.value, 'loyaltyPercentage')

        isDirty.value.loyaltyPercentage = true
    }

    function isWebOnlyChange() {
        // if (isWebOnly.value != props.originalCardData.isWebOnly) isDirty.value.isWebOnly = true
        // else isDirty.value.isWebOnly = false
        isDirty.value.isWebOnly = true
    }

    function installmentChange() {
        // if (installment.value != props.originalCardData.installment) isDirty.value.installment = true
        // else isDirty.value.installment = false
        isDirty.value.installment = true
    }

    function marketingDiscountBlur() {
        //formatValue(marketingDiscount.value, 'marketingDiscount')

        // if (marketingDiscount.value != props.originalCardData.MarketingDiscount) isDirty.value.marketingDiscount = true
        // else isDirty.value.marketingDiscount = false
        isDirty.value.marketingDiscount = true

        marketingDiscountIsEmpty.value = false
    }

    function loyaltyMarketingDiscountBlur() {
        //formatValue(loyaltyMarketingDiscount.value, 'loyaltyMarketingDiscount')

        isDirty.value.loyaltyMarketingDiscount = true
        loyaltyMarketingDiscountIsEmpty.value = false
    }

    watch(productsUserChanges.value, (oldVal, newVal) => {
        let productIds = Object.keys(newVal)
        let dirty = false

        productIds.map(productId => {
            const originalProductData = cardSingleStore.getOriginalProduct(productId)

            if (newVal[productId].hasOwnProperty('selected')) {
                if (newVal[productId].selected != originalProductData.selected) dirty = true
            }
            if (newVal[productId].hasOwnProperty('images')) {
                if (originalProductData.selected) {
                    if (!arraysEqual(newVal[productId].images, originalProductData.katalogProductImages)) dirty = true
                } else {
                    let intersection = imagesIntersection(originalProductData.Product.Gallery, props.originalCardData.katalogProductImages)
                    if (!arraysEqual(newVal[productId].images, intersection)) dirty = true
                }
            }
            if (newVal[productId].hasOwnProperty('description')) {
                if (newVal[productId].description != originalProductData.katalogProductName) dirty = true
            }
        })

        productsAreDirty.value = dirty
    })

    defineExpose({
        saveAndPublishData
    })

    function arraysEqual(firstArr, secondArr) {
        let x = firstArr.sort()
        let y = secondArr.sort()

        if (firstArr.length != secondArr.length) return false

        return x.every((val, index) => val == y[index])
    }
</script>

<style lang="sass" scoped>
.w-33
    width: 33.33%

.mdi-triangle
    transform: rotate(180deg)
    top: 9px
    right: 15px
    z-index: 1

.option-disabled
    opacity: 0.5
    pointer-events: none

.switch
    position: relative
    display: inline-block
    width: 40px
    height: 20px

.switch input
    opacity: 0
    width: 0
    height: 0

.slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #DCE2E8
    -webkit-transition: .4s
    transition: .4s

.slider:before
    position: absolute
    content: ""
    height: 20px
    width: 20px
    left: 0px
    bottom: 0px
    background-color: #B1BBC5
    -webkit-transition: .4s
    transition: .4s

input:checked + .slider:before
    background-color: #373A36

// input:focus + .slider
//   box-shadow: 0 0 1px #2196F3

input:checked + .slider:before
    -webkit-transform: translateX(19px)
    -ms-transform: translateX(19px)
    transform: translateX(19px)

/* Rounded sliders */
.slider.round
    border-radius: 34px

.slider.round:before
    border-radius: 50%

.dropdown-menu-card
    min-width: 19rem

.width-75
    width: 75px !important
</style>

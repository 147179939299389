<template>
    <div
        class="modal fade"
        id="deleteCardModal"
        tabindex="-1"
        aria-labelledby="deleteCardModalLabel"
        aria-hidden="true"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3"
                        id="deleteCardModalLabel"
                    >
                        {{ "delete_card.modal.title".trans() }}
                    </h3>
                    <h5>
                        {{ "delete_card.modal.text".trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button
                        @click="confirmCardDelete"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#drawerBoxEdit"
                        aria-controls="drawerBoxEdit"
                    >
                        {{ "delete_card.modal.delete".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "delete_card.modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue";
    import { useBacklogCardsStore } from "../../js/frontend/stores/backlogCards";
    import { useCardSingleStore } from "../../js/frontend/stores/cardSingle";

    import { deleteCard } from "../../js/frontend/api/card";

    const props = defineProps({
        catalogId: {
            type: Number,
            required: true
        }
    })

    const modal = ref(null);

    const backlogCardsStore = useBacklogCardsStore();
    const cardSingleStore = useCardSingleStore();

    const data = ref(cardSingleStore.getCurrentData);

    async function confirmCardDelete() {
        $(modal.value).modal("hide");
        backlogCardsStore.setLoading(true);

        try {
            await deleteCard(data.value.id.toString());
            backlogCardsStore.setCurrentPage(1);
            await backlogCardsStore.fetchData(props.catalogId);
        } catch (error) {
            throw error;
        }
    }
</script>

import { fetchHealthStatus } from "../api/healthStatus";
import { hotReload } from "../helpers/hotReload";

class HealthStatusModalModal {
    constructor(ModalElem = [], StatusIconElem = []) {
        if (ModalElem.length == 0 || StatusIconElem.length == 0) {
            return false;
        }

        this.ModalElem = ModalElem;
        this.StatusIconElem = StatusIconElem;

        this.endpointUrl = this.ModalElem.data("url");

        this.getHealthStatusTable();
    }

    async getHealthStatusTable() {
        try {
            const responseHtml = await fetchHealthStatus(this.endpointUrl);

            this.ModalElem.find(".health-status-table-wrapper").html(responseHtml.data);

            this.checkForErrors()
        } catch (err) {
            console.log(err);
        }
    }

    checkForErrors() {
        const numberOfErrors = this.ModalElem.find(".health-status-table").data("errors-num")

        if (numberOfErrors > 0) {
            this.StatusIconElem.addClass('has-errors')
            this.StatusIconElem.find('p').text(numberOfErrors)
        }
    }
}

export default HealthStatusModalModal;

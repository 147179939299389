<template>
    <div class="d-flex mt-4">
        <button
            class="btn btn-outline text-decoration-underline p-0 text-black fs-5 mx-auto"
            @click="handleSelectAll"
        >
            {{ "catalog.box.select_all".trans() }}
        </button>
    </div>

    <div v-if="isLoading" class="spinner-wrapper d-flex">
        <span
            class="spinner-border spinner-border mx-auto mt-4"
            role="status"
        ></span>
    </div>

    <div
        v-else
        class="boxes-container mt-1 overflow-auto custom-scrollbar"
        :class="{ 'border border-1 shadow-sm': isEmpty }"
        ref="root"
    >
        <draggable
            v-model="cardsData"
            v-bind="dragOptions"
            group="cards"
            item-key="id"
            class="draggable-container draggable-container-sidebar"
            @add="addCard"
            ref="draggableComponent"
        >
            <template #item="{ element }">
                <Card
                    :id="element.id"
                    :name="element.Label"
                    :page="element.Page"
                    :itemsCount="element.katalogProducts"
                    :projection="element.projection"
                    :status="element.Status"
                    :confirmed="element.confirmed"
                    :images="element.katalogProductImages"
                    :onlyWeb="element.isWebOnly"
                    :designerNote="element.DesignerNote"
                    :isVirtual="element.isVirtual"
                    :catalogId="element.Catalog"
                    :virtualFrom="element.virtualFrom"
                    :isLoyalty="element.isLoyalty"
                    :topic="element.Topic"
                    :removed="element.Removed"
                    :approved="element.approved"
                    :maskType="element.maskType"
                    :LoyaltyMinItemCount="element.LoyaltyMinItemCount"
                    :LoyaltyItemCount="element.LoyaltyItemCount"
                    :LoyaltyGratisItemCount="element.LoyaltyGratisItemCount"
                />
            </template>
        </draggable>
        <div v-if="isLoadingMore" class="spinner-wrapper d-flex">
            <span
                class="spinner-border spinner-border mx-auto mt-4"
                role="status"
            ></span>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, watch, nextTick } from "vue";
import Card from "./Card.vue";
import draggable from "../draggable/vuedraggable.umd.min.js";
import { returnToBacklog } from "../../../js/frontend/api/card";
import { useActivePagesStore } from "../../../js/frontend/stores/activePages";
import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";

const props = defineProps({
  cards: {
    type: Array,
    required: true,
  },
  catalogId: {
    type: Array,
    required: true,
  },
  activePagesIds: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
});

const activePagesStore = useActivePagesStore();
const backlogCardsStore = useBacklogCardsStore();

const filtersPayload = ref(backlogCardsStore.getPayload);
const currentPage = ref(backlogCardsStore.getCurrentPage);
const totalNumberOfPages = ref(backlogCardsStore.getTotalPages)

const root = ref(null);
const draggableComponent = ref(null);

const cardsData = computed(() => props.cards);
const cardsDOM = computed(() => {
  // Add a dependency on cardsData so that this computed property re-runs
  // every time the cards change.
  const dummy = cardsData.value;
  
  return draggableComponent.value.componentStructure.children.map((item) => item.el);
});

const allCardsSelected = ref(false);

const isEmpty = computed(() => (cardsData.value || []).length === 0);

// flag to ensure loadMoreCards is only triggered once per threshold reached.
const isLoadingMore = ref(false);

// This function is called once when scrolled to half the container height to load additional backlog cards
async function loadMoreCards() {
  isLoadingMore.value = true;

  try {
    const nextPage = currentPage.value + 1
    backlogCardsStore.setCurrentPage(nextPage)

    await backlogCardsStore.fetchData(props.catalogId, filtersPayload.value);

    if (selectAllCards.value) {
        selectAllCards();
    }

  } catch (error) {
    console.error("Error loading more cards:", error);
  } finally {
    isLoadingMore.value = false;
  }
}

async function onHalfScroll() {
  await loadMoreCards()
}

function handleScroll() {
  if (!root.value) return;
  
  const container = root.value;
  const scrollTop = container.scrollTop;
  const scrollHeight = container.scrollHeight;
  
  const halfHeight = scrollHeight / 2;
  
  if (scrollTop >= halfHeight && !isLoadingMore.value && currentPage.value < totalNumberOfPages.value) {
    onHalfScroll();
  }
}

watch(
  () => props.isLoading,
  async (newVal) => {
    if (!newVal) {
      await nextTick(); // Wait until the DOM updates
      if (root.value) {
        root.value.addEventListener("scroll", handleScroll);
      }
    }
  }
);

onUnmounted(() => {
  if (root.value) {
    root.value.removeEventListener("scroll", handleScroll);
  }
});

async function addCard(event) {
  const cardId = event.item.__draggable_context.element.id;
  const pageId = event.item.__draggable_context.element.Page;
  const multiple = event.items ? event.items.length > 1 : false;

  let cardIds = [];

  try {
    if (multiple) {
      cardIds = event.items.map((item) => item.__draggable_context.element.id);
    } else {
      cardIds = [cardId];
    }
    
    await returnToBacklog(pageId, cardIds);

    activePagesStore.fetchData(props.activePagesIds);
    backlogCardsStore.setCurrentPage(1);
    backlogCardsStore.fetchData(props.catalogId);
  } catch (error) {
    window.location.reload(); // TODO: maybe find a way to undo d&d containers states
    throw error;
  }
}

function handleSelectAll() {
  if (cardsDOM.value.length === 0) return;

  const firstCard = cardsDOM.value[0];
  const firstCardIsChecked = $(firstCard).hasClass("multi-drag");

  if (firstCardIsChecked) {
    deselectAllCards();
  } else {
    selectAllCards();
  }
}

function selectAllCards() {
    selectAllCards.value = true;

    cardsDOM.value.forEach((card) => {
        draggableComponent.value.select(card);
    });
}

function deselectAllCards() {
    selectAllCards.value = false;

    cardsDOM.value.forEach((card) => {
        draggableComponent.value.deselect(card);
    });
}

const dragOptions = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
  selectedClass: "multi-drag",
  multiDrag: true,
  handle: ".name-container",
  sort: false,
  scrollSensitivity: 100,
  scrollSpeed: 10000,
  forceFallback: true,
};
</script>


<style lang="sass" scoped>
.boxes-container
    min-height: 89px
    max-height: calc(90vh - 70px)

    .draggable-container
        min-height: 75px
</style>

<template>
    <div
        class="box bg-light p-2 rounded-2 mb-1"
        ref="root"
        v-on="isVirtual ? { click: () => editBoxClick(true) } : {}"
        :data-highlight-card-id="id"
    >
        <div
            class="d-flex justify-content-between align-items-center"
            @click.stop
        >
            <div class="name-container d-flex align-items-center w-100">
                <div class="d-flex flex-column">
                    <i class="mdi mdi-checkbox-blank-outline text-dark fs-3 cursor-pointer check-input"></i>
                    <i class="mdi mdi-checkbox-marked text-dark fs-3 cursor-pointer check-input"></i>

                    <i v-if="approved" class="mdi mdi-check-circle fs-3 approved-icon"></i>
                </div>
                <label class="form-check-label d-flex align-items-center cursor-pointer text-dark fw-bold fs-4 ps-2 w-100"
                    :class="{ 'text-decoration-line-through': removed }"
                >
                    <span v-if="isVirtual" class="fw-normal is-virtual me-1">
                        {{ "(" + "catalog.box.virtual".trans() + ")" }}
                    </span>
                    {{ name }}
                </label>
                <div
                    v-if="isUpdated"
                    class="new-data bg-dark text-white fw-bold fs-6 px-1 w-fit text-uppercase rounded-1 mt-1 me-1"
                >
                    {{ "catalog.box.new_data".trans() }}
                </div>
            </div>
            <div class="tooltips-container text-nowrap">
                <i
                    v-if="onlyWeb"
                    class="mdi mdi-laptop fs-4 text-red cursor-pointer me-1 with-tooltip"
                    data-bs-container=".tooltips-container"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="'catalog.box.web_only.tooltip'.trans()"
                ></i>
                <i v-else class="mdi mdi-laptop fs-4 text-muted me-1"></i>

                <i
                    v-if="designerNote"
                    class="mdi mdi-bell-alert fs-4 text-red with-tooltip"
                    data-bs-container=".tooltips-container"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="designerNote"
                ></i>
                <i v-else class="mdi mdi-bell-alert fs-4 text-muted"></i>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="text-dark fs-5">
                {{ "catalog.box.products".trans() }}
                <span class="fw-bold"> {{ itemsCountFormatted }} </span>
            </div>
            <span class="vr mx-2 my-1 text-light-secondary"> </span>
            <div class="text-dark fs-5">
                {{ "catalog.box.projection".trans() }}
                <span class="fw-bold"> {{ projectionFormatted }} </span>
            </div>
            <span class="vr mx-2 my-1 text-light-secondary"> </span>
            <div class="text-dark fs-5">
                <CardStatus :status="status" :confirmed="confirmed" />
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="text-dark fs-5">
                {{ "catalog.box.topic".trans() }}
                <span class="fw-bold"> {{ topic }} </span>
            </div>
        </div>
        <div v-if="images" class="d-flex mt-2">
            <img
                v-for="(image, index) in imagesFormatted"
                :key="index"
                :src="image"
                class="product-image border rounded-1 me-2"
                alt="Image"
                :onerror="logAndSwapFailedImgSrc"
            />
        </div>
        <div v-if="showSeeMoreButton" class="dropdown">
            <button class="dropdown-toggle btn btn-outline text-decoration-underline text-dark p-0 mt-2 d-block fs-5"
                role="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                {{ "catalog.box.see_all".trans() }}
            </button>
            <div class="dropdown-menu overflow-auto">
                <img
                    v-for="(image, index) in allImages"
                    :key="index"
                    :src="image"
                    class="product-image border rounded-1 me-2"
                    alt="Image"
                    :onerror="logAndSwapFailedImgSrc"
                />
            </div>
        </div>

        <button
            v-if="isVirtual"
            @click.stop="deleteVirtual"
            class="btn btn-outline text-decoration-underline text-dark p-0 mt-2 d-block fs-5"
        >
            {{ "catalog.box.virtual.delete".trans() }}
        </button>
        <div v-else>
            <button
                class="drawer-box-edit-trigger-button btn btn-dark text-white fw-bold text-uppercase rounded py-2 mt-2 d-block fs-5"
                @click.stop="editBoxClick(false)"
            >
                {{ "catalog.box.edit".trans() }}
            </button>
        </div>
        <LoyaltyFlag v-if="props.isLoyalty" class="mt-2" :maskType="props.maskType" :LoyaltyMinItemCount="props.LoyaltyMinItemCount" :LoyaltyItemCount="props.LoyaltyItemCount" :LoyaltyGratisItemCount="props.LoyaltyGratisItemCount" />
    </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";
import { useCardSingleStore } from "../../../js/frontend/stores/cardSingle";
import { useGeneralStore } from "../../../js/frontend/stores/general";
import { deleteVirtualCard } from "../../../js/frontend/api/card";
import { logAndSwapFailedImgSrc } from "../../../js/frontend/api/log";

import LoyaltyFlag from "../Flags/LoyaltyFlag";
import CardStatus from "../CardStatus";

const props = defineProps({
    id: {
        type: Number,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    page: {
        type: Number,
        required: true,
    },
    itemsCount: {
        type: Number,
        required: true,
    },
    projection: {
        type: Number,
        required: true,
    },
    status: {
        type: String,
        required: true,
    },
    confirmed: {
        type: String,
        required: true
    },
    images: {
        type: Array,
        required: true,
    },
    onlyWeb: {
        type: Boolean,
        required: true,
    },
    designerNote: {
        type: String,
        required: true,
    },
    isVirtual: {
        type: Boolean,
        required: true,
    },
    catalogId: {
        type: Number,
        required: true,
    },
    virtualFrom: {
        type: Number,
        required: true,
    },
    isLoyalty: {
        type: Boolean,
        required: true,
    },
    topic: {
        type: String,
        required: true,
    },
    removed: {
        type: String,
        required: true,
    },
    approved: {
        type: Boolean,
        required: true,
    },
    maskType: {
        type: String,
        required: false
    },
    LoyaltyMinItemCount: {
        type: String,
        required: false
    },
    LoyaltyItemCount: {
        type: String,
        required: false
    },
    LoyaltyGratisItemCount: {
        type: String,
        required: false
    }
});

const root = ref(null);

const backlogCardsStore = useBacklogCardsStore();
const cardSingleStore = useCardSingleStore();
const generalStore = useGeneralStore();

const showSeeMoreButton = computed(() => props.images.length > 5);

// api returns [] when there are 0 products
const itemsCountFormatted = computed(() =>
    props.itemsCount.length == 0 ? 0 : props.itemsCount
);

// format numbers to be dot separated
const projectionFormatted = computed(
    () => props.projection.toLocaleString("de-DE") + " EUR"
);

// if an image is in root assets folder the url looks like //image.png
const allImages = computed(() => {
    return props.images
        .map((url) => (url[1] == "/" ? url.substring(1) : url));
});

// if an image is in root assets folder the url looks like //image.png
const imagesFormatted = computed(() => {
    return props.images
        .slice(0, 5)
        .map((url) => (url[1] == "/" ? url.substring(1) : url));
});

onMounted(() => {
    initTooltips();
});

onBeforeUnmount(() => {
    destroyTooltips();
});

async function editBoxClick(isVirtual) {
    generalStore.setisFullPageLoading(true)
    const myOffcanvas = document.getElementById("drawerBoxEdit");
    const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
    const cardId = isVirtual ? props.virtualFrom : props.id;

    const isLocked =
        (await cardSingleStore.fetchData(cardId)) == "locked" ? true : false;

    if (isLocked) {
        generalStore.setisFullPageLoading(false)
        const lockedCardModal = new bootstrap.Modal(
            document.getElementById("lockedCardModal")
        );
        lockedCardModal.show();
    } else {
        generalStore.setisFullPageLoading(false)
        bsOffcanvas.show();
    }
}

const initTooltips = () => {
    $(root.value).find(".with-tooltip").tooltip("enable");
};

const destroyTooltips = () => {
    $(root.value).find(".with-tooltip").tooltip("dispose");
};

const deleteVirtual = async () => {
    try {
        await deleteVirtualCard(props.virtualFrom.toString());
        backlogCardsStore.setCurrentPage(1);
        await backlogCardsStore.fetchData(props.catalogId);
    } catch (error) {
        throw error;
    }
};
</script>

<style lang="sass" scoped>
.box
    user-select: none
    
.form-check-input
    margin-top: -4px

.is-virtual
    margin-bottom: 2px

.product-image
    width: 80px
    height: 80px
    object-fit: contain

.dropdown-menu
    max-width: 500px
    
    &.show
        display: flex

.text-decoration-line-through
    text-decoration-thickness: 2px !important

.mdi-checkbox-blank-outline
    margin-top: -1px
    display: inline-block

.mdi-checkbox-marked
    margin-top: -1px
    display: none

.multi-drag
    .mdi-checkbox-blank-outline
        display: none

    .mdi-checkbox-marked
        display: inline-block

.card-highlight
    background-color: #FAF4E7 !important
</style>

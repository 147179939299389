import axios from "axios";
import { showErrorToast } from '../helpers/generalErrorHandler'

async function fetchHealthStatus(url) {
    if (!url) return []

    try {
        const response = await axios.get(url)

        return response.data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

export {
    fetchHealthStatus
}
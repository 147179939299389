function mapCardData(data) {
    if (!data) return []

    const dataMapped = data.map(card => {
        return {
            'id': card.id || null,
            'Label': card.Label || '',
            'Page': card.Page || null,
            'Position': card.Position || null,
            'Status': card.Status || '',
            'confirmed': card.Confirmed ? 'confirmed' : 'not-confirmed',
            'projection': card.projection || 0,
            'katalogProducts': card.katalogProducts || [],
            'isVirtual': card.isVirtual || false,
            'virtualFrom': card.virtualFrom || null,
            'isLoyalty': card.Type == 'loyalty',
            'Removed': card.Removed || false,
            'unreadCommentCount': card.unreadCommentCount || 0,
            'DesignerNote': card.DesignerNote || null,
            'isWebOnly': card.isWebOnly || false,
            'approved': card.approved || false,
            'maskType': card.masterMask.maskType || null,
            'LoyaltyMinItemCount': card.LoyaltyMinItemCount || null,
            'LoyaltyItemCount': card.LoyaltyItemCount || null,
            'LoyaltyGratisItemCount': card.LoyaltyGratisItemCount || null
        }
    })

    return dataMapped
}

function mapCardDataOverview(data) {
    if (!data) return []

    const dataMapped = data.map(card => {
        return {
            'id': card.id || null,
            'Brand': card.Brand || null,
            'Label': card.Label || '',
            'displayMeasureValue': card.displayMeasureValue || '',
            'PriceFrom': card.PriceFrom || 0,
            'Price': card.Price || 0,
            'Percentage': card.Percentage || 0,
            'Images': card.Images || [],
            'showPriceWarning': card.showPriceWarning || false,
            'isLoyalty': card.Type == 'loyalty',
            'unreadCommentCount': card.unreadCommentCount || 0,
            'LowestPrice': card.LowestPrice || 0,
            'productCodes': card.productCodeImageMap || null,
            'maskType': card.masterMask.maskType || null,
            'LoyaltyMinItemCount': card.LoyaltyMinItemCount || null,
            'LoyaltyItemCount': card.LoyaltyItemCount || null,
            'LoyaltyGratisItemCount': card.LoyaltyGratisItemCount || null
        }
    })

    return dataMapped
}

function mapImageData(data) {
    if (!data) return []

    const dataMapped = data.map(image => {
        return {
            'id': image.id || null,
            'filename': image.filename || '',
            'origin': image.origin || '',
            'preview': image.preview || '',
            'thumbnail': image.thumbnail || ''
        }
    })

    return dataMapped
}

export { mapCardData, mapCardDataOverview }

<template>
    <div class="p-2 mb-1 bg-light" ref=root v-on="element.isVirtual ? { click: () => editBoxClick(false) } : {}"
        :data-highlight-card-id="element.id"
    >
        <div class="page-card-body" :id="'card-' + element.id">
            <div class="top-row d-flex align-items-start">
                <i class="mdi mdi-checkbox-blank-outline text-dark fs-3 cursor-pointer check-input"></i>
                <i class="mdi mdi-checkbox-marked text-dark fs-3 cursor-pointer check-input"></i>
                <label class="form-check-label d-flex align-items-start text-dark fw-bold fs-4 w-100 cursor-pointer">
                    <div class="d-flex flex-column align-items-center">
                        <div class="number-container mx-2">
                            <p class="bg-white m-0 p-1 fw-bold fs-7 rounded-1 text-black text-center">
                                {{ element.Position || 'x' }}
                            </p>
                        </div>
                        <i v-if="element.approved" class="mdi mdi-check-circle fs-3 approved-icon"></i>
                    </div>
                    <p class="name-container fw-bold fs-4 text-black mb-1"
                        :class="{ 'text-decoration-line-through': element.Removed }"
                    >
                        <span v-if="element.isVirtual" class="fw-normal">{{ "(" + "catalog.box.virtual".trans() + ")" }}</span>
                        {{ element.Label }}
                    </p>
                </label>
                <div class="actions-container text-dark ms-auto nowrap" :data-for="'card-' + element.id">
                    <button class="btn btn-outline p-0 d-flex align-items-center text-decoration-underline text-dark fs-5 mb-2 w-100" @click.stop="editBoxClick(false)">
                        {{ 'catalog.box.edit'.trans() }}
                    </button>
                </div>
            </div>
            
            <div class="bottom-row">
                <div class="d-flex align-items-center justify-content-between">
                    <p class="mb-0 text-black fs-6">
                        <i class="mdi mdi-comment-text-multiple-outline me-1 fs-4 text-muted"
                            :class="{ 'text-red': element.unreadCommentCount > 0 }"
                        ></i>
                        <span class="vr mx-1 text-muted"></span>
                        {{ 'catalog.box.projection'.trans() }} <span class="fw-bold">{{ (element.projection || '0') + ' EUR' }}</span>
                        <span class="vr mx-1 text-muted"></span>
                        <CardStatus :status="element.Status" :confirmed="element.confirmed" />
                    </p>
                    <div class="tooltips-container text-nowrap">
                        <i
                            v-if="element.isWebOnly"
                            class="mdi mdi-laptop fs-4 text-red cursor-pointer me-1 with-tooltip"
                            data-bs-container=".tooltips-container"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="'catalog.box.web_only.tooltip'.trans()"
                        ></i>
                        <i v-else class="mdi mdi-laptop fs-4 text-muted me-1"></i>

                        <i
                            v-if="element.DesignerNote"
                            class="mdi mdi-bell-alert fs-4 text-red with-tooltip"
                            data-bs-container=".tooltips-container"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="element.DesignerNote"
                        ></i>
                        <i v-else class="mdi mdi-bell-alert fs-4 text-muted"></i>
                    </div>
                </div>
            </div>
            <div v-if="element.isUpdated"
                class="new-data bg-dark text-white fw-bold fs-6 px-1 w-fit text-uppercase rounded-1 mt-1"
            >
                {{ 'catalog.box.new_data'.trans() }}
            </div>
        </div>
        <LoyaltyFlag v-if="element.isLoyalty" class="mt-1" :maskType="element.maskType" :LoyaltyMinItemCount="element.LoyaltyMinItemCount" :LoyaltyItemCount="element.LoyaltyItemCount" :LoyaltyGratisItemCount="element.LoyaltyGratisItemCount" />
    </div>
</template>

<script setup>
    import { onBeforeUnmount, onMounted, ref } from 'vue'
    import { useCardStore } from '../../js/frontend/stores/card'
    import { useActivePagesStore } from '../../js/frontend/stores/activePages'
    import { useBacklogCardsStore } from '../../js/frontend/stores/backlogCards'
    import { useCardSingleStore } from '../../js/frontend/stores/cardSingle'
    import { useGeneralStore } from "../../js/frontend/stores/general";

    import LoyaltyFlag from "../components/Flags/LoyaltyFlag"
    import CardStatus from "./CardStatus"

    const props = defineProps({
        element: {
            type: Object,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        },
        activePagesIds: {
            type: Array,
            required: true
        }
    })

    const emit = defineEmits(['productsShow'])

    const cardStore = useCardStore()
    const activePagesStore = useActivePagesStore()
    const backlogCardsStore = useBacklogCardsStore()
    const cardSingleStore = useCardSingleStore()
    const generalStore = useGeneralStore();

    const root = ref(null)
    const dropdownToggler = ref(null)

    onMounted(() => {
        // detach the dropdown in order to go over the overflow container
        $(dropdownToggler.value).on('show.bs.dropdown', function() {
            const id = $(this).parent().data('for')
            const originalParent = $(`#${id}`).find('.top-row')

            $('body').append($(this).parent().css({
                position: 'absolute',
                left: $(this).offset().left,
                top: $(this).offset().top - 2
            }).detach())

            originalParent.addClass('add-padding')
        })

        // return the detached dropdown
        $(dropdownToggler.value).on('hidden.bs.dropdown', function() {
            const id = $(this).parent().data('for')
            const originalParent = $(`#${id}`).find('.top-row')

            originalParent.append($(this).parent().css({
                position: 'relative',
                left: '0',
                top: '0'
            }).detach())

            originalParent.removeClass('add-padding')
        })
    })

    function showProducts(cardId, cardTitle) {
        emit('productsShow', cardTitle)
        cardStore.fetchCardProducts(cardId)
    }

    async function editBoxClick(isVirtual) {
        generalStore.setisFullPageLoading(true)
        const myOffcanvas = document.getElementById("drawerBoxEdit");
        const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
        const cardId = isVirtual ? props.element.virtualFrom : props.element.id;

        $(dropdownToggler.value).dropdown("hide");

        const isLocked =
            (await cardSingleStore.fetchData(cardId)) == "locked" ? true : false;

        if (isLocked) {
            generalStore.setisFullPageLoading(false)
            const lockedCardModal = new bootstrap.Modal(
                document.getElementById("lockedCardModal")
            );
            lockedCardModal.show();
        } else {
            generalStore.setisFullPageLoading(false)
            bsOffcanvas.show();
        }
    }
</script>

<style lang="sass" scoped>
    .bg-light
        user-select: none

    .with-tooltip
        margin-top: -3px

    .menu-button
        &:hover
            text-decoration: underline

    .text-decoration-line-through
        text-decoration-thickness: 2px !important

    .top-row
        &.add-padding
            padding-right: 28px

    .bottom-row, .new-data
        margin-left: 65px

    .mdi-checkbox-blank-outline
        margin-top: -6px
        display: inline-block

    .mdi-checkbox-marked
        margin-top: -6px
        display: none

    .multi-drag
        .mdi-checkbox-blank-outline
            display: none

        .mdi-checkbox-marked
            display: inline-block
            
    .card-highlight
        background-color: #FAF4E7 !important
</style>

import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { fetchBacklog, fetchAll } from "../api/card";
import {
    fetchTopics,
    fetchDepartmentMasks,
    fetchRobniProgram,
} from "../api/catalog";

export const useBacklogCardsStore = defineStore("backlogCards", () => {
    const data = ref(null);
    const allCards = ref(null);
    const time = ref(null);
    const loading = ref(true);
    const payload = ref({});

    const currentPage = ref(1);
    const perPage = ref(30);
    const totalPages = ref(null);
    const totalCards = ref(null);

    const topics = ref(null);
    const departmentMasks = ref(null);
    const robniProgram = ref(null);
    const loadingFilters = ref(true);

    const getData = computed(() => data);
    const getAllCards = computed(() => allCards);
    const getTime = computed(() => time);
    const isLoading = computed(() => loading);
    const getCurrentPage = computed(() => currentPage);
    const getTotalPages = computed(() => totalPages);
    const getTotalCards = computed(() => totalCards);

    const getTopics = computed(() => topics);
    const getDepartmentMasks = computed(() => departmentMasks);
    const getRobniProgram = computed(() => robniProgram);
    const isLoadingFilters = computed(() => loadingFilters);
    const getPayload = computed(() => payload);

    async function fetchData(catalogId) {
        try {
            if (currentPage.value == 1) {
                loading.value = true;
            }
            
            const response = await fetchBacklog(catalogId, payload.value, currentPage.value, perPage.value);

            if (currentPage.value == 1) {
                data.value = response.data;

                totalPages.value = Math.ceil(response.totalNumberOfCards / perPage.value);
                totalCards.value = response.totalNumberOfCards;

                time.value = response.time;
            } else {
                data.value = [...data.value, ...response.data]
            }
            
            loading.value = false;
        } catch (error) {
            loading.value = false;
            throw error;
        }
    }

    async function fetchAllCards(catalogId) {
        try {
            loading.value = true;

            const response = await fetchAll(catalogId);

            allCards.value = response;

            loading.value = false;
        } catch (error) {
            loading.value = false;
            throw error;
        }
    }

    async function fetchSingleFilterData(name, catalogId) {
        try {
            loadingFilters.value = true;

            if (name == "topics") {
                const response = await fetchTopics(catalogId);
                topics.value = response;
            } else if (name == "department-masks") {
                const response = await fetchDepartmentMasks(catalogId);
                departmentMasks.value = response;
            } else if (name == "robni-program") {
                const response = await fetchRobniProgram(catalogId);
                robniProgram.value = response;
            }

            loadingFilters.value = false;
        } catch (error) {
            loadingFilters.value = false;
            throw error;
        }
    }

    function setPayload(payloadObj) {
        payload.value = payloadObj;
    }

    function setLoading(isLoading) {
        loading.value = isLoading;
    }

    function setCurrentPage(page) {
        currentPage.value = page;
    }

    return {
        getData,
        getAllCards,
        getTopics,
        getDepartmentMasks,
        getRobniProgram,
        getTime,
        isLoading,
        getCurrentPage,
        getTotalPages,
        getTotalCards,
        isLoadingFilters,
        fetchData,
        fetchAllCards,
        fetchSingleFilterData,
        setLoading,
        setPayload,
        getPayload,
        setCurrentPage
    };
});

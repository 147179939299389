<template>
    <div class="product mb-4 text-dark fs-5 border-bottom pb-2" ref="root">
        <div v-if="isRemoved" class="overlay"></div>
        <div class="d-flex w-100">
            <span class="me-1 text-black">#{{ index }}</span>
            <div class="w-100">
                <template v-if="showFlags">
                    <p v-if="isRemoved" class="m-0 bg-danger text-white text-uppercase rounded-1 px-1 mb-2 w-fit fw-bold fs-6">
                        {{ 'box.product.flag.removed'.trans() }}
                    </p>
                    <p v-else-if="isAdded" class="m-0 bg-green text-white text-uppercase rounded-1 px-1 mb-2 w-fit fw-bold fs-6">
                        {{ 'box.product.flag.added'.trans() }}
                    </p>
                    <p v-else-if="isChanged" class="m-0 bg-blue text-white text-uppercase rounded-1 px-1 mb-2 w-fit fw-bold fs-6">
                        {{ 'box.product.flag.changed'.trans() }}
                    </p>
                </template>
                <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                        <input v-model="isSelected"
                            @click="handleProductSelected"
                            class="form-check-input me-2 cursor-pointer"
                            type="checkbox"
                        />
                        <i v-if="data.approved" class="mdi mdi-check-circle fs-4 approved-icon"></i>
                    </div>
                    <div class="dropdown">
                        <button class="btn btn-shadow dropdown-toggle image-container position-relative p-0"
                            type="button"
                            data-bs-toggle="dropdown"
                            :data-bs-auto-close="totalImagesNumber > 0 ? 'false' : 'outside'"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ref="imageDropdownToggler"
                        >
                            <template v-if="selectedImages.length > 0">
                                <div class="position-absolute top-0 start-100 translate-middle px-1 bg-dark text-white rounded-pill fw-bold fs-6">
                                    {{ selectedImages.length }}
                                </div>
                                <img class="product-image-thumbnail border rounded-1"
                                    :src="firstSelectedImage.thumbnail"
                                    :alt="firstSelectedImage.filename"
                                    :onerror="logAndSwapFailedImgSrc"
                                >
                            </template>
                            <div v-else class="product-image-placeholder"></div>
                        </button>
                        <div class="dropdown-menu dropdown-menu-images p-3">
                            <p class="fw-bold text-dark fs-3 mb-1">{{ "box.product.images".trans() }}</p>
                            <template v-if="totalImagesNumber > 0">
                                <p class="text-dark fs-5">
                                    {{ "box.product.images.selected".trans() }}
                                    {{ selectedImages.length }}
                                    {{ "box.product.images.from".trans() }}
                                    {{ totalImagesNumber }}
                                    {{ "box.product.images.in_catalog".trans() }}
                                </p>
                                <div class="overflow-container overflow-auto">
                                    <div class="text-center d-flex">
                                        <div v-for="(image, index) in allImages" :key="image.id">
                                            <div @click="handleImageSelect(image.id, $event)"
                                                class="image-container border w-fit me-2 cursor-pointer"
                                                :class="{ 'outlined': selectedImages.indexOf(image.id) >= 0 }"
                                            >
                                                <img class="product-image-preview"
                                                    :src="image.preview"
                                                    :alt="image.filename"
                                                    :onerror="logAndSwapFailedImgSrc"
                                                >
                                            </div>
                                            <button @click="showFancybox(index)"
                                                class="fancybox-trigger btn btn-shadow text-dark text-decoration-underline fs-5 ps-0 pe-2"
                                            >
                                                {{ 'box.product.image.see_full'.trans() }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items center mt-2">
                                    <button class="btn btn-dark fw-bold text-uppercase rounded me-1"
                                        type="button"
                                        @click="confirmImageSelection"
                                    >
                                        {{ "box.product.images.confirm".trans() }}
                                    </button>
                                    <button class="btn btn-outline fw-bold text-uppercase rounded"
                                        type="button"
                                        @click="revertImageSelection"
                                    >
                                        {{ "box.product.images.revert".trans() }}
                                    </button>
                                </div>
                            </template>
                            <p v-else class="text-dark fs-5">{{ "box.product.images.empty".trans() }}</p>
                        </div>
                    </div>
                    <div class="ms-2">
                        <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">
                            {{ "box.product.code".trans() }}
                        </p>
                        <p v-if="oldCode" class="mb-0 text-dark text-decoration-line-through">
                            {{ oldCode }}
                        </p>
                        <p class="mb-0">{{ data.Code }}</p>
                    </div>
                    <span class="vr ms-2 text-light"></span>
                    <div class="ms-2">
                        <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">
                            {{ "box.product.name".trans() }}
                        </p>
                        <!-- <p class="mb-0 fw-bold box-drawer-tooltip with-tooltip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="titleTooltip"
                        >
                            {{ title }}
                        </p> -->
                        <p v-if="oldTitle" class="mb-0 text-dark text-decoration-line-through">
                            {{ oldTitle }}
                        </p>
                        <p class="mb-0 fw-bold">
                            {{ title }}
                        </p>
                    </div>
                    <p class="mb-0 fw-bold text-uppercase fs-6 ms-auto">
                        {{ "box.product.availability".trans() }}
                    </p>
                    <div v-if="oldAvailability" class="bg-soft-secondary px-1 ms-1 w-fit rounded">
                        <p class="text-dark fw-bold fs-6 mb-0 text-decoration-line-through">{{ oldAvailability }}</p>
                    </div>
                    <div class="bg-soft-secondary px-1 ms-1 w-fit rounded">
                        <p class="text-dark fw-bold fs-6 mb-0">{{ data.Availability }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 mb-1">
            <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">
                {{ "box.product.erp_name".trans() }}
            </p>
            <p v-if="oldErpName" class="mb-0 text-dark text-decoration-line-through">
                {{ oldErpName }}
            </p>
            <p class="mb-0 fw-bold">
                {{ data.ErpName ? data.ErpName : ' -' }}
            </p>
        </div>
        <div class="product-prices-container d-flex mt-1">
            <div class="me-1 w-25">
                <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                    {{ "box.price_data.currency".trans() }} <br>
                    {{ "box.price_data.discount".trans() }}
                </label>
                <input v-if="hasOldPrices" class="form-control text-dark text-decoration-line-through mb-1"
                    :class="{ 'invisible': !oldLowestPrice }"
                    type="text"
                    :value="oldLowestPrice"
                    disabled
                />
                <input class="form-control text-dark"
                    type="text"
                    :value="lowestPrice"
                    disabled
                />
            </div>
            <div class="me-1 w-25">
                <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                    {{ "box.price_data.currency".trans() }} <br>
                    {{ "box.price_data.regular_price".trans() }}
                </label>
                <input v-if="hasOldPrices" class="form-control text-dark text-decoration-line-through mb-1"
                    :class="{ 'invisible': !oldPrice }"
                    type="text"
                    :value="oldPrice"
                    disabled
                />
                <input class="form-control text-dark"
                    type="text"
                    :value="price"
                    disabled
                />
            </div>
            <div class="me-1 w-25">
                <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                    {{ "box.price_data.currency".trans() }} <br>
                    {{ "box.price_data.per_unit".trans() }}
                </label>
                <input v-if="hasOldPrices" class="form-control text-dark text-decoration-line-through mb-1"
                    :class="{ 'invisible': !oldUnitPrice }"
                    type="text"
                    :value="oldUnitPrice"
                    disabled
                />
                <input class="form-control text-dark"
                    type="text"
                    :value="unitPrice"
                    disabled
                />
            </div>
            <div class="me-1 w-25">
                <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                    <br>
                    {{ "box.price_data.percentage".trans() }}
                </label>
                <input v-if="hasOldPrices" class="form-control text-dark text-decoration-line-through mb-1"
                    :class="{ 'invisible': !oldPercentage }"
                    type="text"
                    :value="oldPercentage"
                    disabled
                />
                <input class="form-control text-dark"
                    type="text"
                    :value="percentage"
                    disabled
                />
            </div>
        </div>
        <div v-if="props.draftData.pairState && props.draftData.pairState == 'paired'">
            <p class="text-dark fw-bold mt-3 mb-0">
                {{ 'Loyalty:'.trans() }}
            </p>
            <div class="product-prices-container d-flex mt-1">
                <div class="me-1 w-25">
                    <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                        {{ "box.price_data.currency".trans() }} <br>
                        {{ "loyalty.price".trans() }}
                    </label>

                    <input class="form-control text-dark"
                        type="text"
                        :value="loyaltyLowestPrice"
                        disabled
                    />
                </div>
                <div class="me-1 w-25">
                    <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                        {{ "box.price_data.currency".trans() }} <br>
                        {{ "box.price_data.regular_price".trans() }}
                    </label>

                    <input class="form-control text-dark"
                        type="text"
                        :value="loyaltyPriceRegular"
                        disabled
                    />
                </div>
                <div class="me-1 w-25">
                    <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                        {{ "box.price_data.currency".trans() }} <br>
                        {{ "box.price_data.per_unit".trans() }}
                    </label>

                    <input class="form-control text-dark"
                        type="text"
                        :value="loyaltyUnitPrice"
                        disabled
                    />
                </div>
                <div class="me-1 w-25">
                    <label class="form-label text-muted text-uppercase fw-bold fs-7 lh-1 mb-0">
                        <br>
                        {{ "loyalty.percentage".trans() }}
                    </label>

                    <input class="form-control text-dark"
                        type="text"
                        :value="loyaltyPercentage"
                        disabled
                    />
                </div>
            </div>
        </div>
        <div class="comment-container mt-2">
            <p class="mb-0 text-uppercase fs-6 text-muted fw-bold">
                {{ "box.product.note".trans() }}
            </p>
            <p v-if="oldNote" class="mb-0 text-dark text-decoration-line-through">
                {{ oldNote }}
            </p>
            <p class="mb-0 text-dark">
                {{ note }}
            </p>
        </div>
        <div v-if="isSelected" class="description-container mt-2">
            <label for="box-product-description" class="form-label text-uppercase fs-6 text-muted fw-bold mb-0">
                {{ "box.product.description".trans() }}
            </label>
            <p v-if="oldDescription" class="mb-0 text-dark text-decoration-line-through">
                {{ oldDescription }}
            </p>
            <input v-model="description"
                id="box-product-description"
                class="form-control text-dark"
                type="text"
                :placeholder="'box.product.description.placeholder'.trans()"
                @change="updateDescription"
            />
        </div>
    </div>
</template>

<script setup>
    import { useCardSingleStore } from "../../../js/frontend/stores/cardSingle"
    import { logAndSwapFailedImgSrc } from "../../../js/frontend/api/log"
    import CardStatusEnum from "../../../js/frontend/helpers/card-status-enum"
    import { ref, computed, onMounted } from "vue"

    import { Fancybox } from "@fancyapps/ui"
    import "@fancyapps/ui/dist/fancybox/fancybox.css"

    const props = defineProps({
        cardStatus: {
            type: String,
            required: true
        },
        currentData: {
            type: Object,
            required: true,
        },
        draftData: {
            type: Object,
            required: true,
        },
        originalData: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true
        },
        isAdded: {
            type: Boolean,
            required: true
        },
        isRemoved: {
            type: Boolean,
            required: true
        },
        isChanged: {
            type: Boolean,
            required: true
        },
        showFlags: {
            type: Boolean,
            required: true
        },
        cardLevelSelectedImages: {
            type: Array,
            required: true
        }
    })

    const isSavedAsDraft = () => {
        return props.cardStatus === CardStatusEnum.SAVED_AS_DRAFT
    }

    const emit = defineEmits('productImagesUpdated', 'productImagesReverted', 'productDescriptionUpdated', 'productSelectedUpdated')

    const cardSingleStore = useCardSingleStore()

    const data = computed(() => {
        if (props.draftData) return props.draftData
        else return props.currentData
    })

    const root = ref(null)

    // Depending on draft node state, we looking for product name on original node or on draft node;
    // Because, if card is saved as draft, than draft has higher priority for entered product name
    const description = ref(isSavedAsDraft() ? props.draftData.katalogProductName : (props.currentData || {}).katalogProductName || '');

    const imageDropdownToggler = ref(null)

    // Depending on draft node state, we looking for selected status on original node or on draft node;
    // Because, if card is saved as draft, than draft has higher priority for selection
    const selectedProducts = cardSingleStore.getSelectedProducts
    let selectedImages = null
    if (selectedProducts.value.includes(data.value.Product.id)) {
        selectedImages = ref([...data.value.katalogProductImages])

        let galleryImageIds = data.value.Product.Gallery.map(image => image.id)

        selectedImages.value = selectedImages.value.filter(id => galleryImageIds.indexOf(id) >= 0)
    } else {
        selectedImages = ref(imagesIntersection())
    }

    let originalImages = [...selectedImages.value]

    const allImages = ref(data.value.Product.Gallery)

    const firstSelectedImage = computed(() => {
        if (selectedImages.value.length > 0) {
            let id = selectedImages.value[0]
            let image = data.value.Product.Gallery.filter(image => image.id == id)[0]

            return image
        }
    })

    const isSelected = computed(() => selectedProducts.value.indexOf(data.value.Product.id) >= 0 )
    const lowestPrice = computed(() => {
        if (data.value.LowestPrice) return data.value.LowestPrice.replace('.', ',')
        else return 0
    })
    const price = computed(() => {
        if (data.value.Price) return data.value.Price.replace('.', ',')
        else return 0
    })
    const unitPrice = computed(() => {
        if (data.value.UnitPrice) return data.value.UnitPrice.replace('.', ',')
        else return 0
    })
    const percentage = computed(() => {
        if (data.value.Percentage) return data.value.Percentage.replace('.', ',') + ' %'
        else return '0 %'
    })

    const loyaltyLowestPrice = computed(() => {
        if (data.value.LoyaltyPrice) return data.value.LoyaltyPrice.replace('.', ',')
        else return 0
    })
    const loyaltyRegularPrice = computed(() => {
        if (data.value.LoyaltyPriceRegular) return data.value.LoyaltyPriceRegular.replace('.', ',')
        else return 0
    })
    const loyaltyUnitPrice = computed(() => {
        if (data.value.LoyaltyUnitPrice) return data.value.LoyaltyUnitPrice.replace('.', ',')
        else return 0
    })
    const loyaltyPercentage = computed(() => {
        if (data.value.LoyaltyPercentage) return data.value.LoyaltyPercentage.replace('.', ',') + ' %'
        else return '0 %'
    })

    const hasCurrentData = computed(() => props.currentData != null)
    const hasDraftData = computed(() => props.draftData != null)

    const title = computed(() => {
        if (data.value.Title) {
            return data.value.Title
        }
        else return data.value.ErpTextInCatalog
    })
    // const titleTooltip = computed(() => {
    //     if (data.value.Title) {
    //         return data.value.ErpTextInCatalog

    //     } else if (hasCurrentData.value && hasDraftData.value && props.currentData.ErpTextInCatalog != props.draftData.ErpTextInCatalog) {
    //         return `${props.currentData.ErpTextInCatalog} > ${props.draftData.ErpTextInCatalog}`

    //     } else {
    //         return null
    //     }
    // })

    const note = computed(() => {
        if (hasDraftData.value) {
            return props.draftData.MarketingNote
        } else if (hasCurrentData.value) {
            return props.currentData.MarketingNote
        } else {
            return null
        }
    })

    // old values which are shown with strikethrough line
    const oldNote = computed(() => findOldData('MarketingNote'))
    const oldCode = computed(() => findOldData('Code'))
    const oldDescription = computed(() => findOldData('katalogProductName'))
    const oldAvailability = computed(() => findOldData('Availability'))
    const oldErpName = computed(() => findOldData('ErpName'))

    const oldTitle = computed(() => {
        if (findOldData('Title')) return findOldData('Title')
        else return findOldData('ErpTextInCatalog')
    })

    const oldLowestPrice = computed(() => {
        if (findOldData('LowestPrice')) return findOldData('LowestPrice').replace('.', ',')
        else return false
    })
    const oldPrice = computed(() => {
        if (findOldData('Price')) return findOldData('Price').replace('.', ',')
        else return false
    })
    const oldUnitPrice = computed(() => {
        if (findOldData('UnitPrice')) return findOldData('UnitPrice').replace('.', ',')
        else return false
    })
    const oldPercentage = computed(() => {
        if (findOldData('Percentage')) return findOldData('Percentage').replace('.', ',') + ' %'
        else return false
    })

    const hasOldPrices = computed(() => oldLowestPrice.value || oldPrice.value || oldUnitPrice.value || oldPercentage.value)

    const totalImagesNumber = computed(() => {
        if (hasDraftData.value) {
            return props.draftData.Product.Gallery.length
        } else if (hasCurrentData.value) {
            return props.currentData.Product.Gallery.length
        } else {
            return 0
        }
    })

    function handleProductSelected() {
        cardSingleStore.updateSelectedProducts(data.value.Product.id)
        emit('productSelectedUpdated', { 'productId': data.value.Product.id, 'isSelected': isSelected.value })
    }

    function handleImageSelect(imageId, e) {
        if (selectedImages.value.includes(imageId)) {
            const index = selectedImages.value.indexOf(imageId)

            if (index !== -1) {
                $(e.target).closest('.image-container').removeClass('outlined')
                selectedImages.value.splice(index, 1)
            }
        } else {
            $(e.target).closest('.image-container').addClass('outlined')
            selectedImages.value.push(imageId)
        }
    }

    function updateDescription() {
        emit('productDescriptionUpdated', { 'productId': data.value.Product.id, 'description': description.value })
    }

    function confirmImageSelection() {
        originalImages = [...selectedImages.value]

        $(imageDropdownToggler.value).dropdown('hide')
    }

    function revertImageSelection() {
        selectedImages.value = [...originalImages]

        $(imageDropdownToggler.value).dropdown('hide')
    }

    function showFancybox(index) {
        const data = allImages.value.map(image => {
            return {
                'src': image.origin,
                'type': 'image'
            }
        })

        new Fancybox(data, {
            startIndex: index,
            Thumbs: false,
            idle: false,
            Carousel: {
                infinite: false
            },
            Toolbar: {
                display: {
                    left: [],
                    middle: [],
                    right: ['close']
                }
            },
            tpl: {
                closeButton:
                    '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" tabindex="-1"><path d="M20 20L4 4m16 0L4 20"/></svg></button>',
                main:
                    `<div class="fancybox__container" role="dialog" aria-modal="true" aria-label="{{MODAL}}" tabindex="-1">
                        <div class="fancybox__backdrop"></div>
                        <div class="fancybox__carousel"></div>
                        <div class="fancybox__footer">
                            <div class="fancybox__infobar mb-4 mt-2" tabindex="-1">
                                ${'fancybox.photo'.trans()}<span data-fancybox-current-index></span>${'fancybox.from'.trans()}<span data-fancybox-count></span>
                            </div>
                        </div>
                    </div>`
            }
        })
    }

    function imagesIntersection() {
        let result = []
        const gallery = data.value.Product.Gallery.map(image => image.id)
        const cardImages = props.cardLevelSelectedImages

        for (let elem of gallery){
            if (cardImages.includes(elem)){
                result.push(elem)
            }
        }

        return result
    }

    function findOldData(dataName) {
        if (hasCurrentData.value && hasDraftData.value && (props.currentData[dataName] != props.draftData[dataName])) {
            return props.currentData[dataName]
        } else {
            return null
        }
    }

    onMounted(() => {
        $(root.value).find('.with-tooltip').tooltip('enable')

        $(imageDropdownToggler.value).on('hidden.bs.dropdown', function() {
            emit('productImagesUpdated', { 'productId': data.value.Product.id, 'images': selectedImages.value })
        })
    })
</script>

<style lang="sass" scoped>
    .bg-green
        background-color: green
    .image-container
        box-sizing: border-box

        &.outlined
            outline: 5px solid #373A36
            outline-offset: -5px
            border-radius: 3px

        .product-image-preview
            height: 200px
            width: 200px
            object-fit: contain

    .overflow-container
        max-width: 790px

    .dropdown-menu-images
        min-width: 805px

    .product-image-thumbnail
        width: 40px
        height: 40px
        object-fit: contain

    .product-image-placeholder
        width: 40px
        height: 40px
        background-image: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2873_8720)"><rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="%23DDE3E9" stroke="%23B1BBC5"/><g clip-path="url(%23clip1_2873_8720)"><path d="M10.0345 10.069L29.9655 29.2643" stroke="%23B1BBC5" stroke-linecap="square"/><path d="M29.9655 10.069L10.0345 29.2643" stroke="%23B1BBC5" stroke-linecap="square"/></g></g><defs><clipPath id="clip0_2873_8720"><rect width="40" height="40" fill="white"/></clipPath><clipPath id="clip1_2873_8720"><rect width="20" height="19.3333" fill="white" transform="translate(10 10)"/></clipPath></defs></svg>')

    .box-drawer-tooltip
        padding-top: 1px

    .product
        position: relative

        .overlay
            position: absolute
            background-color: rgba(255, 255, 255, .5)
            top: 20px
            right: 0px
            bottom: 0px
            left: 0px
            z-index: 1

    .old-price-field
        margin-left: 16px
</style>

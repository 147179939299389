import axios from "axios";
import { showErrorToast } from '../helpers/generalErrorHandler'

async function addToPage(pageId, cardIds, position) {
    if (!pageId || !cardIds || !position) return;

    try {
        await axios.post(`/api/catalog/page/assign-cards`, {
            pageId: pageId,
            cardIds: cardIds,
            position: position,
        });
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function returnToBacklog(pageId, cardIds) {
    if (!pageId || !cardIds) return;

    try {
        await axios.post(`/api/catalog/page/unassign-card`, {
            pageId: pageId,
            cardIds: cardIds,
        });
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function fetchBacklog(catalogId, payload, currentPage, perPage) {
    const payloadKeys = Object.keys(payload);
    const payloadIsEmpty = payloadKeys.length == 0;
    const timeStamp = new Date();

    const date = padZero(timeStamp.getDate());
    const month = padZero(timeStamp.getMonth() + 1);
    const year = timeStamp.getFullYear();
    const hour = padZero(timeStamp.getHours());
    const minute = padZero(timeStamp.getMinutes());
    const second = padZero(timeStamp.getSeconds());

    const timestampOfFetch = `${date}.${month}.${year}. ${hour}:${minute}:${second}h`;

    let requestUrl = `/api/catalog/${catalogId}/backlog-cards?page=${currentPage}&perPage=${perPage}`;

    if (!payloadIsEmpty) {
        payloadKeys.map((key) => {
            requestUrl += "&" + key + "=" + payload[key].join(",");
        });
    }

    try {
        const response = await axios.get(requestUrl);

        if (!response.data.data) return { data: [], time: timestampOfFetch }

        const totalNumberOfCards = response.data.data && response.data.data.length > 0 ? response.data.data[0].totalCards : 0;

        let data = response.data.data.map((item) => {
            return {
                id: item.id || null,
                Label: item.Label || null,
                Page: item.Page || null,
                Position: item.Position || null,
                katalogProducts: item.katalogProducts || 0,
                projection: item.projection || 0,
                Status: item.status || null,
                confirmed: item.Confirmed ? 'confirmed' : 'not-confirmed',
                katalogProductImages: item.katalogProductImages || [],
                isVirtual: item.isVirtual || false,
                DesignerNote: item.DesignerNote || null,
                isUpdated: item.isUpdated || false,
                isWebOnly: item.isWebOnly || false,
                virtualFrom: item.virtualFrom || null,
                Catalog: item.Catalog || null,
                isLoyalty: item.Type == 'loyalty',
                Topic: item.Topic || null,
                Removed: item.Removed || false,
                approved: item.approved|| false,
                maskType: item.masterMask.maskType || null,
                LoyaltyMinItemCount: item.LoyaltyMinItemCount || null,
                LoyaltyItemCount: item.LoyaltyItemCount || null,
                LoyaltyGratisItemCount: item.LoyaltyGratisItemCount || null
            };
        });

        data = data || [];

        return { data: data, time: timestampOfFetch, totalNumberOfCards: totalNumberOfCards };
    } catch (error) {
        showErrorToast(error)
        return { data: [], time: timestampOfFetch };
    }
}

async function fetchAll(catalogId) {
    if (!catalogId) return

    try {
        const response = await axios.get(`/api/catalog/${catalogId}/merge-split-card-list`);

        let data = response.data.data.map((item) => {
            return {
                id: item.id || null,
                Label: item.Label || null,
                Page: item.Page || null,
                Position: item.Position || null,
                katalogProducts: item.katalogProducts || 0,
                projection: item.projection || 0,
                Status: item.status || null,
                katalogProductImages: item.katalogProductImages || [],
                isVirtual: item.isVirtual || false,
                DesignerNote: item.DesignerNote || null,
                isUpdated: item.isUpdated || false,
                isWebOnly: item.isWebOnly || false,
                virtualFrom: item.virtualFrom || null,
                Catalog: item.Catalog || null,
            };
        });

        return data || [];

    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function reorder(cardIds) {
    if (!cardIds) return;

    try {
        await axios.post("/api/catalog/page/reoder-cards", {
            catalogCards: cardIds,
        });
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function fetchSingleCardData(cardId, force) {
    if (!cardId) return;

    let url = `/api/catalog/card/${cardId}`;
    force ? (url = url + "?force=1") : "";

    try {
        const response = await axios.get(url);

        const data = {
            draft: response.data.data.draft || {},
            current: response.data.data.current || {},
        };

        return data || emptyCardSingle(cardId);

    } catch (error) {
        showErrorToast(error)

        if (error.response.status == 423) {
            return "locked-card";
        }

        return emptyCardSingle(cardId);
    }
}

async function deleteCard(cardId) {
    if (!cardId) return;

    try {
        await axios.post("/api/catalog/card/delete-card", {
            cardId: cardId,
        });
    } catch (error) {
        showErrorToast(error)
        return {};
    }
}

async function splitCard(originCardId, newCardId, products) {
    let data = null;

    if (newCardId != "create-new-card") {
        data = {
            originCard: originCardId,
            newCard: newCardId,
            products: products,
        };
    } else {
        data = {
            originCard: originCardId,
            products: products,
        };
    }

    try {
        const response = await axios.post(
            "/api/catalog/card/split",
            data
        );

        return response.data;
    } catch (error) {
        showErrorToast(error)
        return {};
    }
}

async function mergeCard(originCardId, destinationCardId, config) {
    try {
        await axios.post("/api/catalog/card/merge", {
            originCardId,
            destinationCardId,
            config,
        });
    } catch (error) {
        showErrorToast(error)
        return {};
    }
}

async function createVirtualCard(cardId) {
    try {
        await axios.post("/api/catalog/card/create-virtual", {
            cardId,
        });
    } catch (error) {
        showErrorToast(error)
        return {};
    }
}

async function deleteVirtualCard(cardId) {
    try {
        await axios.post("/api/catalog/card/delete-virtual-card", {
            cardId,
        });
    } catch (error) {
        showErrorToast(error)
        return {};
    }
}

async function saveAndPublish(payload) {
    if (!payload) return;

    try {
        await axios.post("/api/catalog/catalog-card/save", payload);
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function saveDraft(payload) {
    if (!payload) return;

    try {
        await axios.post("/api/catalog/catalog-card/save", payload);
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

async function fetchProducts(cardId) {
    if (!cardId) return;

    try {
        const response = await axios.post(
            "/api/catalog/card/products",
            {
                cardId: cardId,
            }
        );

        let data = response.data.data.map((product) => {
            return {
                name: product.Title || null,
                code: product.Code || null,
            };
        });

        return data || [];
    } catch (error) {
        showErrorToast(error)
        return [];
    }
}

async function fetchBrands(searchTerm) {
    let response = {}

    try {
        if (searchTerm) response = await axios.get(`/api/brands?term=${searchTerm}`)
        else response = await axios.get('/api/brands')

        let data = response.data.data.map(brand => {
            return {
                'id': brand.value || null,
                'text': brand.label || null
            }
        });

        return data || []

    } catch (error) {
        showErrorToast(error)
        return [];
    }
}

async function unlock(cardId) {
    if (!cardId) return

    try {
        await axios.post('/api/catalog/unlock-card', {
            'cardId': cardId
        })

    } catch (error) {
        throw error
    }
}

function padZero(el) {
    return ("0" + el).slice(-2);
}

function emptyCardSingle(cardId) {
    return {
        'draft': {
            'id': cardId,
            'Brand': null,
            'Label': null,
            'Description': null,
            'DesignerNote': null,
            'displayMeasureValue': null,
            'LowestPrice': null,
            'Price': null,
            'UnitPrice': null,
            'Percentage': null,
            'PriceFrom': null,
            'isWebOnly': null,
            'Installment': null,
            'MarketingDiscount': null,
            'katalogProductImages': [],
            'katalogProducts': [],
            'virtualCard': null,
            'masterMask': { 'label': null },
            'departmentMask': { 'label': null }
        },
        'current': {
            'id': cardId,
            'Brand': null,
            'Label': null,
            'Description': null,
            'DesignerNote': null,
            'displayMeasureValue': null,
            'LowestPrice': null,
            'Price': null,
            'UnitPrice': null,
            'Percentage': null,
            'PriceFrom': null,
            'isWebOnly': null,
            'Installment': null,
            'MarketingDiscount': null,
            'katalogProductImages': [],
            'katalogProducts': [],
            'virtualCard': null,
            'masterMask': { 'label': null },
            'departmentMask': { 'label': null }
        }
    }
}

export {
    addToPage,
    fetchBacklog,
    fetchBrands,
    returnToBacklog,
    reorder,
    fetchProducts,
    fetchSingleCardData,
    fetchAll,
    saveAndPublish,
    saveDraft,
    deleteCard,
    splitCard,
    mergeCard,
    createVirtualCard,
    deleteVirtualCard,
    unlock
};
